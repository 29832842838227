const NextI18Next = require('next-i18next').default

const NextI18NextInstance = new NextI18Next({
  // next-i18next options
  defaultLanguage: 'zh',
  otherLanguages: [],
  strictMode: false,

  // i18next options
  fallbackLng: 'zh',
  load: 'languageOnly',
})

module.exports = NextI18NextInstance
